import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import { LoadingIcon } from '@app/components/icons/CommonIcons';

type ContainerProps = {
  padding: string;
};
const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${props => props.padding};
`;

type LoadingIconContainerProps = {
  width: string;
};
const LoadingIconContainer = styled.div<LoadingIconContainerProps>`
  width: ${props => props.width};
`;

type LoadingProps = {
  padding?: string;
  width?: string;
  color?: string;
};

const Loading = ({
  padding = '0',
  width = '50px',
  color = themeColors.darkText,
}: LoadingProps) => (
  <Container padding={padding}>
    <LoadingIconContainer width={width}>
      <LoadingIcon width="100%" color={color} />
    </LoadingIconContainer>
  </Container>
);

export default Loading;
