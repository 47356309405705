import { multipleReels } from '@app/api/fixtures/reel';
import { PlaybackLanguages } from '@app/api/resources/PlaybackLanguages';

export const aPlaybackLanguages: PlaybackLanguages = {
  audio_options: ['Tamasheq'],
  subtitle_options: ['English', 'French'],
  media_options: {
    duration: 4321,
    hd: true,
  },
  media_features: ['HD'],
  reels: multipleReels,
};
