import {
  IndustryEvent,
  IndustryEventEntry,
  IndustryEventFilmDetails,
} from '@app/api/resources/IndustryEvent';

export const anIndustryEvent: IndustryEvent = {
  id: 1,
  name: 'Cannes Film Festival',
  slug: 'cannes',
  type: 'festival',
  logo_url:
    'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-small_black.png',
  white_logo_url:
    'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-large_white.png',
  cover_url:
    'https://images.mubicdn.net/images/industry_event/1/cache-222788-1494406355/cover-original.jpg',
};

export const anIndustryEventEntry: IndustryEventEntry = {
  id: 111,
  display_text: 'Cannes',
  industry_event: anIndustryEvent,
  full_display_text: 'Cannes Film Festival',
  status: 'won',
  year: 2019,
};

export const anIndustryEventFilmDetails: IndustryEventFilmDetails = {
  name: 'Cannes Film Festival',
  slug: 'cannes',
  year: 2019,
  entries: ['2019 | Winner: Jury Prize'],
  logo_url: '',
  logo_url_white: '',
  event_type: 'generic',
};

export const someIndustryEvents: IndustryEvent[] = [
  {
    id: 1,
    name: 'Cannes Film Festival',
    slug: 'cannes',
    type: '',
    logo_url: '',
    cover_url: '',
    white_logo_url: '',
  },
  {
    id: 2,
    name: 'Toronto International Film Festival',
    slug: 'toronto',
    type: '',
    logo_url: '',
    cover_url: '',
    white_logo_url: '',
  },
  {
    id: 3,
    name: 'International Film Festival Rotterdam',
    slug: 'rotterdam',
    type: '',
    logo_url: '',
    cover_url: '',
    white_logo_url: '',
  },
  {
    id: 4,
    name: 'BFI London Film Festival',
    slug: 'london',
    type: '',
    logo_url: '',
    cover_url: '',
    white_logo_url: '',
  },
];

export const someIndustryEventEntries: IndustryEventEntry[] = [
  {
    id: 111,
    display_text: 'Cannes',
    full_display_text: 'Cannes Film Festival',
    industry_event: anIndustryEvent,
    status: 'won',
    year: 2019,
  },
  {
    id: 222,
    display_text: 'International Film Festival Rotterdam',
    full_display_text: 'International Film Festival Rotterdam',
    industry_event: anIndustryEvent,
    status: 'nominated',
    year: 2017,
  },
  {
    id: 333,
    display_text: 'Toronto International Film Festival',
    full_display_text: 'Toronto International Film Festival',
    industry_event: anIndustryEvent,
    status: 'won',
    year: 2015,
  },
];

export const someIndustryEventFilmDetails: IndustryEventFilmDetails[] = [
  {
    name: 'Cannes Film Festival',
    slug: 'cannes',
    year: 2019,
    entries: ['2019 | Winner: Jury Prize'],
    logo_url: '',
    event_type: 'generic',
    logo_url_white: '',
  },
  {
    name: 'Toronto International Film Festival',
    slug: 'toronto',
    year: 2019,
    entries: ['2019'],
    event_type: 'generic',
    logo_url: '',
    logo_url_white: '',
  },
  {
    name: 'International Film Festival Rotterdam',
    slug: 'rotterdam',
    year: 2020,
    entries: ['2020'],
    event_type: 'generic',
    logo_url: '',
    logo_url_white: '',
  },
  {
    name: 'BFI London Film Festival',
    slug: 'london',
    year: 2019,
    entries: ['2019'],
    event_type: 'generic',
    logo_url: '',
    logo_url_white: '',
  },
];

export const moreIndustryEvents: IndustryEventEntry[] = [
  {
    id: 193674,
    year: 2018,
    status: 'won',
    display_text: 'Directing Award: U.S. Dramatic',
    full_display_text: '2018 | Winner: Directing Award: U.S. Dramatic',
    industry_event: {
      id: 5,
      name: 'Sundance Film Festival',
      slug: 'sundance',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/5/cache-222793-1494527058/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/5/cache-222793-1494527058/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/5/cache-276449-1509983117/cover-original.jpg',
    },
  },
  {
    id: 396587,
    year: 2022,
    status: 'won',
    display_text: 'Grand Prix',
    full_display_text: '2022 | Winner: Grand Prix',
    industry_event: {
      id: 1,
      name: 'Cannes Film Festival',
      slug: 'cannes',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222790-1494527057/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/1/cache-222788-1494406355/cover-original.jpg',
    },
  },
  {
    id: 383032,
    year: 2022,
    status: 'won',
    display_text: 'Golden Bear',
    full_display_text: '2022 | Winner: Golden Bear',
    industry_event: {
      id: 3,
      name: 'Berlin International Film Festival',
      slug: 'berlinale',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222792-1494527057/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222792-1494527057/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222957-1494430656/cover-original.jpg',
    },
  },
  {
    id: 383035,
    year: 2022,
    status: 'won',
    display_text: 'Best Director',
    full_display_text: '2022 | Winner: Best Director',
    industry_event: {
      id: 3,
      name: 'Berlin International Film Festival',
      slug: 'berlinale',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222792-1494527057/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222792-1494527057/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/3/cache-222957-1494430656/cover-original.jpg',
    },
  },
  {
    id: 398554,
    year: 2022,
    status: 'won',
    display_text: 'Best Direction',
    full_display_text: '2022 | Winner: Best Direction',
    industry_event: {
      id: 6,
      name: 'Locarno International Film Festival',
      slug: 'locarno',
      type: 'festival',
      logo_url:
        'https://images.mubicdn.net/images/industry_event/6/cache-222794-1494527058/logo-small_black.png',
      white_logo_url:
        'https://images.mubicdn.net/images/industry_event/6/cache-222794-1494527058/logo-large_white.png',
      cover_url:
        'https://images.mubicdn.net/images/industry_event/6/cache-222962-1494431497/cover-original.jpg',
    },
  },
];
