import { ReactNode, SyntheticEvent } from 'react';

import {
  LoadingText,
  LoadingWrapper,
  StyledButton,
} from '@app/components/buttons/MubiButton/ButtonStyle';
import Loading from '@app/components/Loading';

const doNothingOnClick = (e: SyntheticEvent) => {
  e.preventDefault();
};

const doClickAction = (e: SyntheticEvent, clickAction: () => void) => {
  e.preventDefault();
  clickAction();
};

const getClickAction = (disabled: boolean, clickAction: () => void) => {
  if (disabled) {
    return (e: SyntheticEvent) => doNothingOnClick(e);
  }
  if (clickAction) {
    return (e: SyntheticEvent) => doClickAction(e, clickAction);
  }
  return () => {};
};

type ButtonProps = {
  clickAction?: () => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  type?: string;
  actionType?: string;
  isLoading?: boolean;
  id?: string;
};

const Button = ({
  clickAction = null,
  disabled = false,
  children = null,
  className = '',
  type = 'button',
  actionType = 'primary',
  isLoading = false,
  id = '',
}: ButtonProps) => {
  const onClick = getClickAction(disabled, clickAction);

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      className={className}
      isLoading={isLoading}
      disabled={isLoading ? true : disabled}
      actiontype={actionType}
      id={id}
    >
      {isLoading ? (
        <>
          <LoadingText>{children}</LoadingText>
          <LoadingWrapper>
            <Loading color="currentColor" width="12px" />
          </LoadingWrapper>
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
