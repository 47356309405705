import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import sub from 'date-fns/sub';

import { aFilm } from '@app/api/fixtures/films';
import { aPlaybackLanguages } from '@app/api/fixtures/playback-languages';
import { Consumable } from '@app/api/resources/Consumables';

const aWeekAgoDateStamp = formatISO(
  sub(new Date(), {
    weeks: 1,
  }),
);

const aMonthAgoDateStamp = formatISO(
  sub(new Date(), {
    months: 1,
  }),
);

const twoDaysFromNowDateStamp = formatISO(
  add(new Date(), {
    days: 2,
    minutes: 1,
  }),
);

const twoWeeksFromNowDateStamp = formatISO(
  add(new Date(), {
    weeks: 2,
  }),
);

const twoMonthsFromNowDateStamp = formatISO(
  add(new Date(), {
    months: 2,
  }),
);

const catalogueOffer = { type: 'catalogue' };
const freeOffer = { type: 'free' };

export type ConsumableDateLabel =
  | 'current'
  | 'leaving_soon'
  | 'expired'
  | 'future';

export type ConsumableType = ['catalogue'] | ['free'] | [];

export const generateConsumable = (
  currentOrExpiredOrFuture: ConsumableDateLabel = 'current',
  types: ConsumableType = [],
  filmId = aFilm.id,
): Consumable => {
  const consumable = {
    film_id: filmId,
    playback_languages: aPlaybackLanguages,
    offered: [],
    film_date_message: {
      detail: [],
      tile: null,
    },
    availability: null,
    available_at: null,
    availability_ends_at: null,
    expires_at: null,
    exclusive: null,
    permit_download: null,
  };

  if (currentOrExpiredOrFuture === 'current') {
    consumable.available_at = aWeekAgoDateStamp;
    consumable.availability_ends_at = twoMonthsFromNowDateStamp;
    consumable.expires_at = twoMonthsFromNowDateStamp;
    consumable.availability = 'live';
  }
  if (currentOrExpiredOrFuture === 'leaving_soon') {
    const someDate = new Date('1985-05-03T03:03:00');
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
    }).format(someDate);

    consumable.available_at = aWeekAgoDateStamp;
    consumable.availability_ends_at = twoDaysFromNowDateStamp;
    consumable.expires_at = twoDaysFromNowDateStamp;
    consumable.availability = 'live';
    consumable.film_date_message = {
      detail: [
        {
          text: `Leaving ${formattedDate}`,
          type: 'expiring',
        },
      ],
      tile: {
        text: `Leaving ${formattedDate}`,
        type: 'expiring',
      },
    };
  }
  if (currentOrExpiredOrFuture === 'expired') {
    consumable.available_at = aMonthAgoDateStamp;
    consumable.availability_ends_at = aWeekAgoDateStamp;
    consumable.expires_at = aWeekAgoDateStamp;
  }
  if (currentOrExpiredOrFuture === 'future') {
    consumable.available_at = twoWeeksFromNowDateStamp;
    consumable.availability_ends_at = twoWeeksFromNowDateStamp;
    consumable.expires_at = twoMonthsFromNowDateStamp;
    const someDate = new Date('1985-05-03T03:03:00');
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
    }).format(someDate);
    consumable.film_date_message = {
      detail: [
        {
          text: `Coming ${formattedDate}`,
          type: 'expiring',
        },
      ],
      tile: {
        text: `Coming ${formattedDate}`,
        type: 'expiring',
      },
    };
  }

  types.forEach(type => {
    switch (type) {
      case 'catalogue':
        consumable.offered.push(catalogueOffer);
        break;
      case 'free':
        consumable.offered.push(freeOffer);
        break;
      default:
        break;
    }
  });

  return consumable;
};
