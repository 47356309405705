import { ContentRating } from '@app/api/resources/Film';

export const aContentRating: ContentRating = {
  rating_code: 'A2',
  description: 'Very naughty this content. Tut tut tut.',
  label: 'adult',
  label_hex_color: '#7A77D7',
  icon_url: '',
};

export const aNotRatedContentRating: ContentRating = {
  label_hex_color: '#7A77D7',
  icon_url: '',
  label: 'not_rated',
  rating_code: 'NOT RATED',
  description:
    'This film has not yet been rated, and may contain adult material.',
};
